@charset 'UTF-8';
@use "sass:math";
@use "../foundation/variables" as *;
@use "../foundation/mixin" as *;
@use "../foundation/base64" as *;

.friendship-main{
  overflow: hidden;
  padding-top: 0;
  margin-top: 0;
  
  @media #{$sp} {
    padding-top: 0;
    margin-top: -10px;
  }
  .c-title__primary{
    &.--vertical{
      @media #{$sp} {
        align-items: flex-start;
        width: auto;
      }
      .p-title__container{
        @media #{$sp} {
          align-items: flex-start;
        }
        .p-title{
          @media #{$sp} {
            line-height: 1.5;
            font-size: 28px;
            margin-right: -1em;
          } 
        }
        .p-title__sub{
          @media #{$sp} {
            margin-top: -15px;
          } 
        }
        .p-title__line{
          @media #{$sp} {
            text-align: left;
            margin: 0;
          } 
        }
      }
    }
  }
}
.friendship-mv{
  height: 465px;
  width: 100%;
  background: url("../images/friendship/img_mv.png") no-repeat center center;
  background-size: cover;
  
  @media #{$sp} {
    background-image: url("../images/friendship/img_mv_sp.png");
    height: 53.33vw;
  }
}
.l-inner{
  &.-rel{
    position: relative;
  }
}
.friendship-lead{
  position: absolute;
  left: 100px;
  top: -400px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  
  @media #{$sp} {
    position: relative;
    left: 0;
    top: 0;
    padding-top: 40px;
    margin-bottom: -32px;
  }  
  &__logo{
    width: 200px;
  
    @media #{$sp} {
      width: 144px;
    }  
  }
  &__info{
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
  
    @media #{$sp} {
      gap: 22px;
    }  
  }
  &__ttl{
    font-size: 24px;
    font-weight: 700;
    line-height: 1.75;
  
    @media #{$sp} {
      font-size: 22px;
    }  
  }
  &__line{
    width: 48px;
    height: 1px;
    background-color: #FFF;
  
    @media #{$sp} {
      background-color: $color-primary;
    }  
  }
  &__body{
    font-size: 14px;
    font-weight: 500;
    line-height: 1.85;
  }
  &__btn{
    .c-btn__more{
      a{
        width: 360px;
        line-height: 1.5;
  
        @media #{$sp} {
          width: 208px;
        }          
        img{
          display: none;
        }
      }
    }
  }
}
.friendship-partner{
  padding: 80px 0;
  background-color: #EBE6E1;
  position: relative;
  z-index: 1;
  
  @media #{$sp} {
    padding: 112px 0 80px;
  }    
  &__lead{
    font-size: 22px;
    font-weight: 700;
    line-height: 1.72;
    text-align: center;
  
    @media #{$sp} {
      text-align: left;
    }    
  }
  &__btn{
    margin-top: 24px;
  }
  &__body{
    margin-top: 40px;
  }
  .p-title__line{
    background-color: #FFF !important;
  }
  .c-title__primary{
    &.--vertical{
      padding-bottom: 26px; 
    }
  }
  &__list{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 30px;
  
    @media #{$sp} {
      grid-template-columns: repeat(2, 1fr) !important;
      gap: 17px !important;
    }       
    li{
      border-radius: 0 32px 0 0;
      overflow: hidden;
  
      @media #{$sp} {
        border-radius: 0 24px 0 0;
      }     
    }
    &.-col3{
      grid-template-columns: repeat(3, 1fr);
      gap: 40px;
    }
  }
}
.friendship-create{
  padding-top: 80px;
  
  @media #{$sp} {
    padding: 80px 24px 0;
  }    
  &__lead{
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  
    @media #{$sp} {
      text-align: left;
    }    
  }
  .c-title__primary{
    &.--vertical{
      padding-bottom: 67px; 
    }
  }
  &__list{
    display: flex;
    justify-content: center;
    gap: 8px;
    position: relative;
    padding-bottom: 80px;
  
    @media #{$sp} {
      display: grid;
      margin: 0 -24px;
      grid-template-columns: repeat(2, 1fr);
      gap: 7px;
      padding-bottom: 40px;
    }      
    &:before{
      content: "";
      background-color: $color-primary;
      width: 100vw;
      height: 165px;
      position: absolute;
      z-index: 1;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      border-radius: 80px 0 0 0;
  
      @media #{$sp} {
        height: 287px;
        border-radius: 40px 0 0 0;
      } 
    }
    &>li{
      width: 200px;
      height: 200px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #FFF;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.75;
      position: relative;
      z-index: 2;
  
      @media #{$sp} {
        width: 42.66vw;
        height: 42.66vw;
        font-size: 14px;
      }         
      &:nth-child(1){
        background-color: #0B7F47;
      }
      &:nth-child(2){
        background-color: #FF754B;
      }
      &:nth-child(3){
        background-color: #2A77C8;
      }
      &:nth-child(4){
        background-color: #F079B9;
      }
    }
  }
}
.friendship-about{
  padding-top: 112px;
  
  @media #{$sp} {
    padding: 80px 24px 0;
  }   
  &__lead{
    font-size: 22px;
    font-weight: 700;
    line-height: 1.72;
    text-align: center;
    margin-bottom: 80px;
  
    @media #{$sp} {
      text-align: left;
    }   
  }
}
.c-title__primary{
  &.-pb40{
    padding-bottom: 40px;
  
    @media #{$sp} {
      padding-bottom: 24px;
    }  
  }
}
.friendship-list{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  padding-bottom: 80px;
  position: relative;
  
  @media #{$sp} {
    grid-template-columns: repeat(1, 1fr) !important;
    gap: 0 !important;
    padding-bottom: 0;
  }   
  &.-col5{
    grid-template-columns: repeat(5, 1fr);
    gap: 29.5px;
  }
  &:before{
    content: "";
    background-color: $color-primary;
    width: 100vw;
    height: calc(100% - 64px);
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    border-radius: 0 80px 0 0;
  
    @media #{$sp} {
      border-radius: 0 40px 0 0 !important;
    }
  }
  &.-rev{
    &:before{
      border-radius: 80px 0 0 0;
      height: calc(100% - 72px);
    }
    &>li{
      &:nth-child(n+2){
        .friendship-list__head{
          &:before{
            left: -19px;
          }
        }
      }
    }
  }
  &>li{
    position: relative;
    z-index: 2;
    
    &:nth-child(n+2){
      .friendship-list__head{
        &:before{
          content: "";
          width: 8px;
          height: 15px;
          background: url("../images/friendship/icon_arrow.svg") no-repeat center center;
          background-size: contain;
          position: absolute;
          top: 50%;
          left: -24px;
          transform: translateY(-50%);
  
          @media #{$sp} {
            left: 50% !important;
            top: -40px;
            transform: translateX(-50%) rotate(90deg);
          }
        }
      }
    }
    &:last-child{
      .friendship-list__body{
        @media #{$sp} {
          padding-bottom: 40px;
        }
      }
    }
  }
  &__head{
    background-color: $base-color;
    color: #FFF;
    position: relative;
  }
  &__body{
    background-color: #FFF;
    padding: 30px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.85;
    min-height: 112px;
    display: flex;
    align-items: center;
  
    @media #{$sp} {
      padding: 24px 24px 56px;
      justify-content: center;
    }
  }
  &__ttl{
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
    font-family: $ffEngSub;
    padding: 20px 16px;
  }
  &__flex{
    display: flex;
    align-items: center;
    padding: 12px 16px;
  
    @media #{$sp} {
      padding: 16px;
    }
  }
  &__num{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    font-family: $ffEngSub;
    color: $base-color;
    flex-shrink: 0;
    flex-grow: 0;
  
    @media #{$sp} {
      width: 32px;
      height: 32px;
    }
  }
  &__label{
    font-size: 22px;
    font-weight: 700;
    flex-shrink: 1;
    flex-grow: 1;
    line-height: 1.45;
    text-align: center;
  }
}
.friendship-examples{
  padding-top: 80px;
  
  @media #{$sp} {
    padding: 84px 24px 0;
  }    
  .examples-title{
    font-size: 16px;
    font-weight: 700;
    line-height: 1.75;
  }
  .examples-discription{
    font-size: 14px;
    font-weight: 500;
    line-height: 1.85;
  }
  .c-card__list_holizontal{
    @media #{$sp} {
      flex-direction: column;
    } 
    .p-content{
      gap: 5px;
    }
    .p-item{
      a{
        @media #{$sp} {
          flex-direction: column;
        } 
      }
    }
    .p-image{
      @media #{$sp} {
        width: auto;
        margin: 0 24px;
      } 
    }
  }
}


    @media #{$pc} {
    }
    @media #{$sp} {
    }