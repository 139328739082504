@charset 'UTF-8';
@use "sass:math";
@use "../foundation/variables" as *;
@use "../foundation/mixin" as *;
@use "../foundation/base64" as *;
.page-outline {
  .c-title__primary {
    @media #{$pc} {
      padding-top: 48px;
    }
    @media #{$sp} {
      padding-top: 24px;
    }
  }
}
.c-outline-intro {
  display: flex;
  @media #{$pc} {
    align-items: flex-start;
    gap: 80px;
  }
  @media #{$sp} {
    flex-direction: column;

    gap: 60px;
  }
}
.c-outline__gallery {
  display: flex;

  flex-wrap: wrap;
  justify-content: space-between;
  @media #{$pc} {
    width: 596px;
  }
  @media #{$sp} {
    width: 100vw;
    margin: 0 -24px;

    gap: 0 8px;
  }
  .p-item {
    display: flex;
    opacity: 0;

    align-items: center;
    @media #{$pc} {
      padding: 0 0 16px 0;
    }
    @media #{$sp} {
      padding: 0 0 8px 0;
    }

    &:nth-child(1) {
      width: math.div(383, 596) * 100%;
    }
    &:nth-child(2) {
      width: math.div(198, 596) * 100%;
    }
    &:nth-child(3) {
      width: math.div(198, 596) * 100%;
      margin-left: auto;
      @media #{$pc} {
        padding-right: 8px;
      }
    }
    &:nth-child(4) {
      width: math.div(256, 596) * 100%;
      margin-right: auto;
      @media #{$pc} {
        padding-left: 8px;
      }
    }
    &:nth-child(5) {
      width: math.div(198, 596) * 100%;

      align-items: flex-end;
    }
    &:nth-child(6) {
      width: math.div(383, 596) * 100%;
    }
  }
}
.is-loaded {
  .c-outline__gallery {
    .p-item {
      animation: galleryStep 1200ms ease-in-out forwards;
      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          animation-delay: 300ms * $i;
        }
      }
    }
  }
}
@keyframes galleryStep {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.c-outline__container {
  display: flex;
  flex-direction: column;

  flex: 1;
  gap: 80px;
  .p-content {
    display: flex;
    flex-direction: column;

    flex: 1;
    gap: 32px;
  }
  .p-title__container {
    display: flex;
    flex-direction: column;
    position: relative;

    gap: 32px;
    .p-title__sub {
      position: relative;
      padding-bottom: 17px;
      /* Monserrat 24 Bold */
      font-family: "Montserrat Alternates";
      font-size: 24px;
      font-weight: 700;
      font-style: normal;
      line-height: 100%; /* 24px */
      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 48px;
        height: 1px;
        background-color: $color-primary;
        content: "";
      }
    }
    .p-title {
      color: var(--Black, #232323);
      /* Noto 40-50 Bold */
      font-weight: 700;
      line-height: 1.25; /* 125% */
      @media #{$pc} {
        font-size: 40px;
      }
      @media #{$sp} {
        font-size: 28px;
      }
    }
  }

  .p-text {
    /* Noto 14-26 Medium */
    font-size: 14px;
    font-weight: 500;
    line-height: 1.8; /* 185.714% */
    letter-spacing: 0.08em;
  }
}

.outline-value {
  .p-image {
    max-width: 724px;
    margin: auto;
    padding-bottom: 80px;
  }
}
.c-outline-value__list {
  display: flex;

  gap: 1px;
  @media #{$sp} {
    flex-direction: column;
  }
  .p-item {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;

    gap: 24px;
    @media #{$pc} {
      padding: 64px 40px;
      border-radius: 80px 0px;
    }
    @media #{$sp} {
      padding: 40px 24px;
      border-radius: 40px 0px;
    }
  }
  .p-heading {
    display: flex;
    flex-direction: column;

    align-items: center;
    gap: 12px;
  }
  .p-title__eng {
    color: var(--Black, #232323);
    /* Monserrat 24 Bold */
    font-family: "Montserrat Alternates";
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    line-height: 100%; /* 24px */
  }
  .p-title {
    /* Noto 14-21 Bold */
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    text-align: justify;
    line-height: 21px; /* 150% */
    letter-spacing: 0.08em;
  }
  .p-text {
    /* Noto 16-28 Medium */
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    text-align: justify;
    line-height: 28px; /* 175% */
    letter-spacing: 0.96px;
  }
  .p-list {
    padding-left: 24px;
    border-left: 1px solid #232323;
    /* Noto 14-26 Medium */
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    text-align: justify;
    line-height: 1.85; /* 185.714% */
    letter-spacing: 0.08em;
  }
}

.c-outline-flow {
  .p-title {
    color: $color-primary;
    /* Noto 14-21 Bold */
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    line-height: 1.5; /* 150% */
    letter-spacing: 0.08em;
    @media #{$pc} {
      padding-bottom: 16px;
    }
    @media #{$sp} {
      padding-bottom: 24px;
    }
  }
  .p-image {
    // max-width: 724px;
    margin: auto;
    // padding-bottom: 80px;
  }
}

.aboutus {
  position: relative;
  z-index: 0;
  &:after {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $color-primary;
    content: "";
    @media #{$pc} {
      height: 460px;
    }
    @media #{$sp} {
      height: calc(100% - 240px);
    }
  }
}
.c-aboutus {
  position: relative;
  .p-aboutus__lsit {
    display: flex;

    gap: 40px;
    @media #{$sp} {
      flex-direction: column;
    }
  }
  .p-item {
    border-radius: 0px 48px 0px 0px;
    background-color: #ffffff;
    @media #{$pc} {
      @include gap(2, 40);
    }
  }
  .p-text {
    padding: 0 40px 40px;
    /* Noto 16-28 Medium */
    font-size: 16px;
    font-weight: 500;
    text-align: justify;
    line-height: 1.75; /* 175% */
    letter-spacing: 0.96px;
  }
  .p-image {
    display: flex;

    align-items: center;
    aspect-ratio: 460 / 210;
    justify-content: center;
  }
  .turns {
    .p-image img {
      width: 145px;
    }
  }
  .bambook {
    .p-image img {
      width: 150px;
    }
  }
  .p-btn {
    display: flex;

    justify-content: center;
  }
}

.aboutus {
  .c-title__primary > .p-title__container > .p-title__sub {
    color: #ffffff;
  }
}
