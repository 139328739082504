@charset 'UTF-8';
@use "sass:math";
@use "../foundation/variables" as *;
@use "../foundation/mixin" as *;
@use "../foundation/base64" as *;
.top-journal {
  overflow-x: hidden;
  .c-title__primary > .p-title__line {
    background: #ebe6e1;
  }
}
.c-top-journal__list_container {
  @media #{$sp} {
    overflow: visible !important;
  }
  .c-pickup__list_ui {
    position: absolute;
    z-index: 10;
    transform: translateY(-50%);
    @media #{$pc} {
      top: 180px;
      left: calc(50% - 550px);
      width: 540px;
    }
    @media #{$sp} {
      top: 90px;
      left: 0;
      width: 100%;
    }
  }
  button {
    cursor: pointer;
  }
  .p-prev {
    position: absolute;
    left: 0;

    @media #{$sp} {
      transform: translateX(-50%) rotate(180deg);
    }
  }
  .p-next {
    position: absolute;
    right: 0;

    @media #{$sp} {
      transform: translateX(50%);
    }
  }
}
