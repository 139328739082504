@charset 'UTF-8';
@use "sass:math";
@use "../foundation/variables" as *;
@use "../foundation/mixin" as *;
@use "../foundation/base64" as *;
.bg-gold {
  background-color: $color-primary;
}
.bg-beige {
  background-color: #ebe6e1;
}
