@charset 'UTF-8';
@use "sass:math";
@use "../foundation/variables" as *;
@use "../foundation/mixin" as *;
@use "../foundation/base64" as *;
.u-fontsize__s {
  font-size: 1.2rem;
  letter-spacing: normal;
}
.color-orenge {
  color: $color-orenge;
}
.color-green {
  color: $color-green;
}
.color-red {
  color: $color-red;
}
.u-left {
  text-align: left;
}
.u-right {
  text-align: right;
}
.u-center {
  text-align: center;
}
.u-strong {
  font-weight: bold !important;
}

.u-normal {
  font-weight: normal !important;
}

.u-attention {
  color: $color-primary;
  font-weight: $bold;
}
