@charset 'UTF-8';
@use "sass:math";
@use "../foundation/variables" as *;
@use "../foundation/mixin" as *;
@use "../foundation/base64" as *;
.top-typo__fixed {
  background-image: url(../images/reloca_pattern.png);
  background-attachment: fixed;
  background-size: cover;
}
.top-outline {
  //
}

.c-outline-gallery {
  overflow: hidden;
  // width: 668px;
  margin: 0 !important;
  @media #{$pc} {
    width: vw(668);
    border-radius: 0 80px 0 0;
  }
  @media #{$sp} {
    width: calc(100% - 24px);
    border-radius: 0 20px 0 0;
  }
  li {
    @media #{$pc} {
      width: 320px;
    }
    @media #{$sp} {
      width: 160px;
    }
  }
  ul {
    // display: flex;
  }
}

.c-slider__marquee,
.c-marquee__bigtypo {
  .swiper-wrapper {
    position: relative;
    -webkit-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
  }
}
.c-top-outline__container {
  display: flex;
  @media #{$pc} {
    padding-bottom: 80px;

    gap: 64px;
  }
  @media #{$sp} {
    flex-direction: column;

    gap: 40px;
  }
}
.c-top-outline__content {
  display: flex;
  flex-direction: column;
  @media #{$pc} {
    gap: 32px;
  }
  @media #{$sp} {
    padding: 0 24px;

    gap: 24px;
  }
  .c-btn__more {
    @media #{$sp} {
      margin-top: 20px;
    }
  }
  .--last {
    margin-top: auto;
  }
  .p-title {
    color: $color-black;
    font-weight: $bold;
    font-style: normal;
    @media #{$pc} {
      font-size: 40px;
      line-height: 1.1;
    }
    @media #{$sp} {
      font-size: 36px;
      line-height: 1.25;
    }
  }
  .p-text {
    color: $color-black;
    /* Noto 14-26 Medium */
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: 0.08em;
  }
}
