@charset 'UTF-8';
@use "sass:math";

// mixin Setting =====================================================================================
@mixin font-size($sizeValue: 1.3) {
  // font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
}

$max-breakpoint: 1440; // This could be one of your media query breakpoint variables
$wide-screen: "(min-width: #{$max-breakpoint}px)"; // Function
@function get-vw($target) {
  // 1 vw is equal to 1% of the viewport width
  $vw-context: ($max-breakpoint * 0.01) * 1px; // 1% viewport width
  @return ($target/$vw-context) * 1vw;
}

$breakpoint-pc: 1280;
@function vw($pxValue) {
  // @return (($pxValue / $breakpoint-pc) * 100) + vw;
  @return (math.div($pxValue, $breakpoint-pc) * 100) + vw;
}
$breakpoint-sp: 375;
@function vwsp($pxValue) {
  // @return (($pxValue / $breakpoint-sp) * 100) + vw;
  @return (math.div($pxValue, $breakpoint-sp) * 100) + vw;
}

@mixin gap($col: 3, $gap: 1) {
  width: calc((100% / #{$col}) - (#{$gap}px * (#{$col} - 1)) / #{$col});
}
