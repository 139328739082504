// ex : @include icon_arrow($color: ffffff);

@mixin icon_arrow($color: ffffff) {
  background-image: url('data:image/svg+xml;utf8,<svg enable-background="new 0 0 9 14" viewBox="0 0 9 14" xmlns="http://www.w3.org/2000/svg"><path d="m1.7 13.7-1.4-1.4 5.3-5.3-5.3-5.3 1.4-1.4 6.7 6.7z" fill="%23#{$color}"/></svg>');
  background-repeat: no-repeat;
  background-size: contain;
}

@mixin icon_arrow_circle02($color: ffffff) {
  background-image: url('data:image/svg+xml;utf8,<svg enable-background="new 0 0 40 40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg"><circle cx="20" cy="20" fill="%23#{$color}" r="20"/><path d="m17.7 26.7-1.4-1.4 5.3-5.3-5.3-5.3 1.4-1.4 6.7 6.7z" fill="#fff"/></svg>');
  background-repeat: no-repeat;
  background-size: contain;
}

@mixin icon_arrow_circle02($color: ffffff) {
  background-image: url('data:image/svg+xml;utf8,<svg enable-background="new 0 0 48 48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><g fill="%23#{$color}"><path d="m24 48c-13.2 0-24-10.8-24-24s10.8-24 24-24 24 10.8 24 24-10.8 24-24 24zm0-47c-12.7 0-23 10.3-23 23s10.3 23 23 23 23-10.3 23-23-10.3-23-23-23z"/><path d="m22.5 29.2-1.5-1.4 3.8-3.8-3.8-3.8 1.5-1.4 5.2 5.2z"/></g></svg>');
  background-repeat: no-repeat;
  background-size: contain;
}
