@charset 'UTF-8';
@use "sass:math";
@use "../foundation/variables" as *;
@use "../foundation/mixin" as *;
@use "../foundation/base64" as *;

.p-title__line{
  &.-gray{
    background-color: #EBE6E1;
  }
}
.journal{
  &-main{
    overflow: hidden; 
  }
  &-cat{
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media #{$sp} {
      margin-top: 80px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    &.-works{
      margin-top: 0;
    }
  }
  &-body{
    margin-top: 80px;
    
    @media #{$sp} {
      margin-top: 70px;
    }
    .c-card__list{
      @media #{$sp} {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 40px;
      }
      .p-item{
        @media #{$sp} {
          width: 100%;
        } 
      }
    }
  }
  &-more{
    margin-top: 80px;
  }
}
.flex-journal-head{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  position: relative;
    
  @media #{$sp} {
    grid-template-columns: repeat(1, 1fr);
  }
  &__img{
    position: relative;
    
    @media #{$sp} {
      margin: 0 -24px;
    }
    &:before{
      content: "";
      width: 100vw;
      height: 187px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: $color-primary;
      z-index: 1;
      pointer-events: none;
    
      @media #{$sp} {
        display: none;
      }
    }
    div{
      position: relative;
      z-index: 2;
      border-radius: 0 80px 0 0;
      overflow: hidden; 
    
      @media #{$sp} {
        border-radius: 0 40px 0 0;
      }  
      img {
        transition: $transition;
      }
    }
  }
  &__info{
    margin-top: 32px;
    
    @media #{$sp} {
      margin-top: 24px;
    }
  }
  &__ttl{
    margin-top: 16px;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.45;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  &__date{
    margin-top: 16px;
    font-size: 14px;
    font-weight: 500;
    color: $color-primary;
    font-family: $ffEng;
    
    @media #{$sp} {
      font-size: 10px;
    }
  }
  a{
    &:hover{
      .flex-journal-head__img{
        img{
          transform: scale(1.05);
        }
      }
    }
  }
}
.journal-cat{
  &__label{
    font-size: 15px;
    font-weight: 700;
    margin-right: 24px;
    font-family: $ffEngSub;
    
    @media #{$sp} {
      margin-right: 0;
      margin-bottom: 24px;
    }
  }
}


.c-category__list {
  &.-black{
    .p-category__item{
      background: $textColor;
      
      &:hover {
        background-color: #FFF;
        color: $textColor;
      }
    }
  }
}
.journal-detail{
  overflow: hidden;
  
  &-body{
    margin-top: 80px;
  }
}
.flex-journal-detail-head{
  position: relative;
  display: flex;
  
  @media #{$sp} {
    flex-direction: column;
  }  
  &__li{
    &:nth-child(1){
      width: 62.8%;
      border-radius: 0 80px 0 0;
      overflow: hidden;
  
      @media #{$sp} {
        width: 100%;
        border-radius: 0 40px 0 0;
      }  
    }
    &:nth-child(2){
      width: calc(100% - 62.8%);
      padding-top: 80px;
      padding-bottom: 80px;
  
      @media #{$sp} {
        width: 100%;
        padding: 0;
      }  
    }
  }
  &__info{
    background-color: $color-primary;
    min-height: 400px;
    padding: 24px 64px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
  
    @media #{$sp} {
      min-height: 0;
      padding: 40px 24px;
      gap: 16px;
    }      
    &:before{
      content: "";
      background-color: $color-primary;
      width: 50vw;
      height: 100%;
      left: 99%;
      top: 0;
      position: absolute;
      display: none;
    }
  }
  &__ttl{
    font-size: 28px;
    font-weight: 700;
    line-height: 1.5;
  
    @media #{$sp} {
      font-size: 22px;
    }    
  }
  &__date{
    font-size: 14px;
    font-weight: 500;
    font-family: $ffEng;
    color: #FFF;
  
    @media #{$sp} {
      font-size: 10px;
    }  
  }
}
.journal-detail-head{
  &.-works{
    position: relative;
    padding-bottom: 48px;
    overflow: hidden;
    
    &>*{
      position: relative;
      z-index: 2;
    }
    &:before{
      content: "";
      background-color: $color-primary;
      width: 100vw;
      height: calc(100% - 266px);
      left: 0;
      bottom: 0;
      position: absolute;
      z-index: 1;

      @media #{$sp} {
        height: calc(100% - 50px);
      } 
    }    
    .flex-journal-detail-head{
      flex-direction: column;

      @media #{$sp} {
        margin: 0 -24px;
      }        
      &__li{
        &:nth-child(1){
          width: 100%;
        }
        &:nth-child(2){
          width: 100%;
          padding: 0;
        }
      }
      &__info{
        background: none;
        min-height: 0;
        padding: 24px 0 0;

        @media #{$sp} {
          padding: 40px 24px 24px;
        } 
      }
    }
    .journal-detail-sns{
      margin-top: 0;
    }
  }
}
.journal-detail-sns{
  margin-top: -32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 2;
  
  @media #{$sp} {
    margin-top: 40px;
    justify-content: center;
  }    
  &__label{
    margin-right: 16px;
    font-family: $ffEng;
    font-size: 13px;
    font-weight: 500;
  
    @media #{$sp} {
      margin-right: 24px;
    }    
  }
  &__list{
    display: flex;
    align-items: center;
    gap: 8px;
  
    li{
      width: 32px;
    }
    @media #{$sp} {
      gap: 24px;
    }    
  }
}
.flex-journal-detail-body{
  display: flex;
  
  @media #{$sp} {
    flex-direction: column-reverse;
  }    
  &__li{
    &:nth-child(1){
      flex-shrink: 1;
      flex-grow: 1;
      
      h1{
        font-size: 28px;
        font-weight: 700;
        line-height: 1.5;
      }
      h2{
        font-size: 24px;
        font-weight: 700;
        line-height: 1.75;
      }
      h3{
        font-size: 16px;
        font-weight: 700;
        line-height: 1.75;
      }
      p{
        font-size: 14px;
        font-weight: 500;
        line-height: 1.85;
      }
      figcaption{
        font-size: 12px;
        font-weight: 500;
        line-height: 1.85;
      }
      a{
        text-decoration: underline;
        
        &:hover{
          text-decoration: none;
        }
      }
      &>*+h1,
      &>*+h2,
      &>*+h3,
      &>*+p,
      &>*+figure{
        margin-top: 40px;
      }
      &>*+div{
        margin-top: 80px;
      }
      #ez-toc-container{
        display: none !important;
      } 
      .wp-block-media-text__content{
        padding: 0 0 0 40px;
      }
      .wp-block-media-text{
        &.has-media-on-the-right{
          .wp-block-media-text__content{
            padding: 0 40px 0 0;
          }
        }
      }
      @media #{$sp} {
        width: 100%;
        flex-shrink: 1;
        flex-grow: 1;
        
        .wp-block-media-text{
          padding: 0 24px;
          
          &.has-media-on-the-right{
            flex-direction: column;
            display: flex;
            
            .wp-block-media-text__content{
              margin-top: 0;
              margin-bottom: 32px;
            }
          }
        }
        .wp-block-media-text__content{
          margin-top: 32px;
          padding: 0 !important;
        }
      }    
    }
    &:nth-child(2){
      width: 35.2%;
      padding-left: 64px;
      flex-shrink: 0;
      flex-grow: 0;
  
      @media #{$sp} {
        width: 100%;
        padding-left: 0;
        margin-bottom: 53px;
      }
      ul{
        width: 100%;
        border-top: 1px solid $color-primary;
        border-bottom: 1px solid $color-primary;
        padding: 48px 0 !important;
        display: flex;
        flex-direction: column;
        gap: 24px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.85;
        counter-reset: number 0; 

        @media #{$sp} {
          padding: 40px 0;
        }    
        >li{
          display: flex;

          &:before{
            counter-increment: number 1;
            content: "Index "counter(number);
            margin-right: 8px;
            font-family: $ffEng;
            flex-shrink: 0;
            flex-grow: 0;
          }
          a{
            &:visited{
              color: $textColor !important;
            }
          }
        }
      }
    }
  }
}
.journal-detail-index{
  width: 100%;
  border-top: 1px solid $color-primary;
  border-bottom: 1px solid $color-primary;
  padding: 48px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.85;
  counter-reset: number 0; 
  
  @media #{$sp} {
    padding: 40px 0;
  }    
  >li{
    display: flex;
    
    &:before{
      counter-increment: number 1;
      content: "Index "counter(number);
      margin-right: 8px;
      font-family: $ffEng;
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
}
.post-block{
  display: flex;
  justify-content: center;
  
  &__inner{
    border-radius: 32px;
    border: 1px solid $color-primary;
    max-width: 960px;
    padding: 48px;
    display: flex;
    align-items: center;
  
    @media #{$sp} {
      border-radius: 24px;
      flex-direction: column;
      padding: 24px;
    }    
  }
  &__li{
    &:nth-child(1){
      width: 230px;
      flex-shrink: 0;
      flex-grow: 0;
  
      @media #{$sp} {
        width: 100%;
        flex-shrink: 1;
        flex-grow: 1;
        padding: 24px 24px 0;
      }    
    }
    &:nth-child(2){
      flex-shrink: 1;
      flex-grow: 1;
      margin-left: 40px;
  
      @media #{$sp} {
        margin-left: 0;
        margin-top: 40px;
      }    
    }
  }
  &__ttl{
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 24px;
  }
  &__body{
    font-size: 14px;
    font-weight: 500;
    line-height: 1.85;
  }
}
#ez-toc-container {
  background: none !important;
  border: none !important;
  border-radius: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  display: block !important;
  margin-bottom: 0 !important;
  padding: 0 !important;
  position: relative !important;
  width: auto !important;
}
.c-category__list .p-category__item{
  a{
    color: inherit;
  }
}
.c-card__list{
  &.js-post-more__target{
    &>li{
      display: none;
      
      &.is-hide{
        opacity: 0;
        transition: opacity .3s;
        
        &.is-show{
          opacity: 1;
        }
      }
    }
  }
}
.c-card__list_holizontal{
  &.js-works-more__target{
    &>li{
      display: none;
    }
  }
}


    @media #{$pc} {
    }
    @media #{$sp} {
    }