@charset 'UTF-8';
@use "sass:math";
@use "../foundation/variables" as *;
@use "../foundation/mixin" as *;
@use "../foundation/base64" as *;

.service-other{
  .c-title__primary{
    &.--vertical{
      @media #{$sp} {
        align-items: flex-start;
        width: auto;
      }
      .p-title__container{
        @media #{$sp} {
          align-items: flex-start;
        }
        .p-title{
          @media #{$sp} {
            line-height: 1.5;
            font-size: 28px;
            margin-right: -1em;
          } 
        }
        .p-title__sub{
          @media #{$sp} {
            margin-top: -15px;
          } 
        }
        .p-title__line{
          @media #{$sp} {
            text-align: left;
            margin: 0;
          } 
        }
      }
    }
  }
}
.service{
  &-head{
    padding-bottom: 48px;
  
    @media #{$sp} {
      padding-bottom: 80px;
    }
  }
  &-content{
    overflow: hidden;
  }
}
.flex-service-head{
  display: flex;
  
  @media #{$sp} {
    flex-direction: column;
  }
  &__li{
    &:nth-child(1){
      width: 50%;
  
      @media #{$sp} {
        width: 100%;
        text-align: center;
      }
      img{
        width: 100%;
  
        @media #{$sp} {
          width: 85%;
        }
      }
    }
    &:nth-child(2){
      width: calc(50% - 48px);
      margin-left: 48px;
      padding-top: 30px;
  
      @media #{$sp} {
        width: 100%;
        margin-left: 0;
        padding-top: 16px;
      }
    }
  }
  &__ttl{
    display: flex;
    flex-direction: column;
    font-size: 38px;
    font-weight: 700;
    line-height: 1.25;
    position: relative;
  
    @media #{$sp} {
      font-size: 28px;
      margin-bottom: 24px;
    }
    small{
      font-size: 28px;
      margin-bottom: 6px;
  
      @media #{$sp} {
        font-size: 22px;
      }
    }
    &:before{
      content: "";
      width: 48px;
      height: 1px;
      background-color: #EBE6E1;
      position: absolute;
      left: 0;
      bottom: -12px;
  
      @media #{$sp} {
        display: none;
      }
    }
  }
  &__body{
    font-size: 20px;
    font-weight: 700;
    margin-top: 24px;
    line-height: 1.8;
  
    @media #{$sp} {
      font-size: 16px;
      margin-top: 0;
    }
  }
}
.service-case{
  position: relative;
  padding: 80px 0 0;
  
  @media #{$sp} {
    padding: 40px 0 0;
  }  
  &>*{
    position: relative;
    z-index: 2;
  }
  &:before{
    content: "";
    background-color: $color-primary;
    width: 100%;
    height: calc(100% + 130px);
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    border-radius: 0 0 0 80px;
  
    @media #{$sp} {
      height: calc(100% + 150px);
      border-radius: 0 0 0 40px;
    }
  }
  &:after{
    content: "";
    background-color: $color-primary;
    width: 50vw;
    height: calc(100% + 130px);
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 0;
  
    @media #{$sp} {
      height: calc(100% + 150px);
    }
  }
}
.service-case-head{
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  
  @media #{$sp} {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px;
  }
  &__label{
    background-color: #FFF;
    border-radius: 12px 0 0 0;
    font-size: 14px;
    font-weight: 700;
    padding: .3em 1.6em;
  
    @media #{$sp} {
      padding: .2em 1.2em;
    }
  }
  &__ttl{
    font-size: 22px;
    font-weight: 700;
    margin-left: 16px;
    line-height: 1.45;
  
    @media #{$sp} {
      margin-left: 0;
      margin-top: 6px;
    }
  }
}
.service-case-body{
  font-size: 16px;
  font-weight: 500;
  line-height: 1.75;
  
  @media #{$sp} {
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 8;
    overflow: hidden;
  }
  &.is-show{
    @media #{$sp} {
      overflow: visible;
      display: block;
    }
  }
}
.service-pic{
  position: relative;
  z-index: 2;
  margin-top: 48px;
  
  @media #{$sp} {
    margin-top: 40px;
  }
  &__list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  
    @media #{$sp} {
      grid-template-columns: repeat(1, 1fr);
      gap: 24px;
    }
    li{
      border-radius: 0 48px 0 0;
      overflow: hidden;
      position: relative;
  
      @media #{$sp} {
        border-radius: 0 24px 0 0;
      }
      &:before{
        @media #{$sp} {
          content: "";
          width: 40px;
          height: 40px;
          background: url("../images/service/icon_viewer.png") no-repeat center center;
          background-size: contain;
          position: absolute;
          right: 8px;
          bottom: 8px;
          z-index: 2;
          pointer-events: none;
        }
      }
    }
  }
}
.service-case-more{
  margin-top: 40px;
}
.c-btn-link__more{
  &.--center{
    margin-right: auto;
  }
  &.--down{
    i{
      transform: rotate(90deg);
    }
  }
}



    @media #{$pc} {
    }
    @media #{$sp} {;
    }