@charset 'UTF-8';
@use "sass:math";
@use "../foundation/variables" as *;
@use "../foundation/mixin" as *;
@use "../foundation/base64" as *;

.top-contact{
  overflow: hidden;
}
.lead-section{
  padding: 48px 0 80px;
  
  @media #{$sp} {
    padding: 24px 0 80px;
  }
}
.l-inner{
  &__960{
    max-width: 1008px;
    margin: auto;
    padding: 0 24px; 
  }
  &__800{
    max-width: 848px;
    margin: auto;
    padding: 0 24px; 
  }
  &__640{
    max-width: 688px;
    margin: auto;
    padding: 0 24px; 
  }
}
.c-title__primary{
  &.--center{
    text-align: center;
  
    @media #{$sp} {
      text-align: left;
    }
  }
}
.about-lead{
  font-size: 28px;
  font-weight: 700;
  line-height: 1.5;
  
  @media #{$sp} {
    font-size: 20px;
    text-align: center;
    margin: 0 -10px;
    width: calc(100vw - 28px);
  }
}
.about-head{
  padding-bottom: 0;
  
  .c-title__primary{
    &.--vertical{
      @media #{$sp} {
        align-items: flex-start;
        width: auto;
      }
      .p-title__container{
        @media #{$sp} {
          align-items: flex-start;
        }
        .p-title{
          @media #{$sp} {
            line-height: 1.5;
            font-size: 28px;
            margin-right: -1em;
          } 
        }
        .p-title__sub{
          @media #{$sp} {
            margin-top: -15px;
          } 
        }
        .p-title__line{
          @media #{$sp} {
            text-align: left;
            margin: 0;
          } 
        }
      }
    }
  }
}
.about-operation{
  background-color: $color-primary;
  padding: 80px 0 0;
  margin-bottom: 40px;
  
  @media #{$sp} {
    padding: 40px 0 24px;
    margin-bottom: 0;
  }
}
.flex-operation{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  
  @media #{$sp} {
    grid-template-columns: repeat(1, 1fr);
    gap: 24px;
  }  
  &__li{
    background-color: #FFF;
    border-radius: 0 48px 0 0;
    padding: 32px 32px 0;
  
    @media #{$sp} {
      padding: 50px 24px 24px;
      border-radius: 0 40px 0 0;
    }
  }
  &__logo{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
  
    @media #{$sp} {
      margin-bottom: 24px;
    }
    img{
      object-fit: scale-down;
      object-position: center center;
      height: 145px;
      width: 100%;
      max-width: 200px;
      
      @media #{$sp} {
        height: 102px;
      }
    }
  }
  &__lead{
    font-size: 16px;
    font-size: 500;
    line-height: 1.75;
  
    @media #{$sp} {
      font-size: 16px;
    }
  }
  &__strength{
    margin-top: 32px;
  
    @media #{$sp} {
      margin-top: 40px;
    }
    &__ttl{
      font-size: 14px;
      font-weight: 700;
      line-height: 1.5;
    }
    &__list{
      font-size: 16px;
      font-size: 500;
      line-height: 1.75;
      margin-top: 8px;
  
      @media #{$sp} {
        font-size: 14px;
        line-height: 1.5;
      }
      li{
        background-color: #000;
        color: #FFF;
        padding: 0 .3em;
  
        @media #{$sp} {
          padding: .4em .8em;
          min-height: 3.8em;
          display: flex;
          align-items: center;
        }        
        &:nth-child(n+2){
          margin-top: 2px;
        }
      }
    }
  }
  &__link{
    margin-top: 32px;
    display: flex;
    justify-content: center;
    
    .c-btn-link__more {
      /*margin-left: 0;*/
    }
  }
}
.about-partner{
  padding: 60px 0 80px;
  
  @media #{$sp} {
    padding: 80px 0;
  }   
}




    @media #{$pc} {
    }
    @media #{$sp} {;
    }