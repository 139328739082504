@charset 'UTF-8';
@use "sass:math";
@use "../foundation/variables" as *;
@use "../foundation/mixin" as *;
@use "../foundation/base64" as *;
.l-section {
  @media #{$pc} {
    // padding: 80px 0 160px;
    padding: 80px 0;
  }
  @media #{$sp} {
    padding: 80px 0;
  }
  &.--first {
    @media #{$pc} {
      padding-top: 0;
    }
    @media #{$sp} {
      padding-top: 0;
    }
  }
}

.l-inner {
  @media #{$pc} {
    max-width: 1160px + 60;
    margin: auto;
    padding: 0 30px;
  }
  @media #{$sp} {
    padding: 0 24px;
  }
}
