@charset 'UTF-8';
@use "sass:math";
@use "../foundation/variables" as *;
@use "../foundation/mixin" as *;
@use "../foundation/base64" as *;

.c-card__list {
  display: flex;

  flex-wrap: wrap;
  @media #{$pc} {
    gap: 40px;
  }
  @media #{$sp} {
    gap: 40px 24px;
  }
  .p-item {
    display: flex;
    flex-direction: column;

    gap: 16px;
    @media #{$pc} {
      @include gap(3, 40);
    }
    @media #{$sp} {
      @include gap(2, 24);

      // width: calc(100% - 32px) !important;
    }
    a {
      display: flex;
      flex-direction: column;
      @media #{$pc} {
        gap: 30px;
      }
      @media #{$sp} {
        gap: 24px;
      }
    }
  }
  .p-image {
    overflow: hidden;
    @media #{$sp} {
      border-radius: 0 24px 0 0;
    }
    img {
      transition: $transition;
    }
  }

  .p-content {
    display: flex;
    flex-direction: column;

    gap: 16px;
  }
  .p-title {
    display: -webkit-box;
    /* Noto 22-32 Bold */
    overflow: hidden;
    font-weight: 700;
    line-height: 1.45; /* 145.455% */
    letter-spacing: 0.88px;

    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    @media #{$pc} {
      font-size: 22px;
    }
    @media #{$sp} {
      font-size: 16px;
    }
  }
  .p-date {
    color: $color-primary;
    /* Poppins 14 Medium */
    font-family: $ffEng;
    font-weight: 500;
    line-height: 100%; /* 14px */
    @media #{$pc} {
      font-size: 14px;
    }
    @media #{$sp} {
      font-size: 10px;
    }
  }
  a:hover {
    .p-image {
      img {
        transform: scale(1.05);
      }
    }
  }
  @media #{$pc} {
    .p-item:nth-child(3n + 1) {
      .p-image {
        img {
          border-radius: 0 64px 0 0;
        }
      }
    }
    .p-item:nth-child(3n + 2) {
      .p-image {
        img {
          border-radius: 0 0 64px 0;
        }
      }
    }
    .p-item:nth-child(3n + 3) {
      .p-image {
        img {
          border-radius: 64px 0 0 0;
        }
      }
    }
    .p-item:nth-child(3n + 4) {
      .p-image {
        img {
          border-radius: 0 0 64px 0;
        }
      }
    }
    .p-item:nth-child(3n + 5) {
      .p-image {
        img {
          border-radius: 64px 0 0 0;
        }
      }
    }
    .p-item:nth-child(3n + 3) {
      .p-image {
        img {
          border-radius: 0 64px 0 0;
        }
      }
    }
  }
}

.c-card__list_holizontal {
  display: flex;

  flex-wrap: wrap;
  @media #{$pc} {
    gap: 40px;
  }
  @media #{$sp} {
    gap: 24px;
  }
  .p-item {
    display: flex;
    flex-direction: column;

    gap: 16px;
    @media #{$pc} {
      @include gap(2, 40);
    }
    a {
      display: flex;
      @media #{$pc} {
        gap: 20px;
      }
      @media #{$sp} {
        gap: 16px;
      }
    }
  }
  .p-image {
    overflow: hidden;
    @media #{$pc} {
      width: 225px;
      border-radius: 0 48px 0 0;
    }
    @media #{$sp} {
      width: 120px;
      border-radius: 0 24px 0 0;
    }
    img {
      transition: $transition;
    }
  }
  .p-content {
    display: flex;
    flex-direction: column;

    flex: 1;
    gap: 16px;
  }
  .p-title {
    display: -webkit-box;
    /* Noto 22-32 Bold */
    overflow: hidden;
    font-weight: 700;
    letter-spacing: 0.88px;

    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    @media #{$pc} {
      font-size: 22px;
      line-height: 1.45;
    }
    @media #{$sp} {
      font-size: 16px;
      line-height: 1.5;
    }
  }
  .p-date {
    color: $color-primary;
    /* Poppins 14 Medium */
    font-family: $ffEng;
    font-size: 14px;
    font-weight: 500;
    line-height: 100%; /* 14px */
  }
  a:hover {
    .p-image {
      img {
        transform: scale(1.05);
      }
    }
  }
  .c-category__list .p-category__item {
    @media #{$pc} {
      font-size: 12px;
    }
    @media #{$sp} {
      font-size: 11px;
    }
  }
}
