@charset 'UTF-8';
@use "sass:math";
@use "../foundation/variables" as *;
@use "../foundation/mixin" as *;
@use "../foundation/base64" as *;
.top-works {
  position: relative;
  padding-bottom: 0 !important;
  &:before {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    content: "";
    @media #{$pc} {
      background-color: #ebe6e1;
    }
    @media #{$sp} {
      background-color: #ffffff;
    }
  }
  &:after {
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: $color-primary;
    content: "";
  }
  .c-title__primary > .p-title__line {
    background: #ebe6e1;
  }
}
.c-top-works__list_container {
  position: relative;
  //
  .c-pickup__list_ui {
    position: absolute;
    z-index: 10;
    transform: translateY(-50%);
    @media #{$pc} {
      top: 180px;
      left: calc(50% - 580px);
      width: 540px;
    }
    @media #{$sp} {
      top: 90px;
      left: 0;
      width: 100%;
    }
  }
  button {
    cursor: pointer;
  }
  .p-prev {
    position: absolute;
    left: 0;
    @media #{$pc} {
      transform: translateX(-50%) rotate(180deg);
    }
    @media #{$sp} {
      transform: rotate(180deg);
    }
  }
  .p-next {
    position: absolute;
    right: 0;
    @media #{$pc} {
      transform: translateX(50%);
    }
    @media #{$sp} {
    }
  }
}
.c-top-works__list {
  overflow: visible !important;
  max-width: 1160px;
  margin: auto;
  @media #{$pc} {
    padding: 0 60px;
  }
  @media #{$sp} {
    max-width: 100%;
    width: 100%;
    padding: 0 24px;
  }
  .p-item {
    @media #{$pc} {
      width: 540px;
    }
    @media #{$sp} {
      // width: 100% !important;
      // width: calc(100% - 48px - 24px - 12px) !important;
    }
  }
  .p-image {
    overflow: hidden;
    position: relative;
    z-index: 1;
    @media #{$pc} {
      border-radius: 0px 80px 0px 0px;
    }
    @media #{$sp} {
      border-radius: 0px 40px 0px 0px;
    }
    img {
      transition: $transition;
    }
  }
  .p-content {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    background-color: #ffffff;
    opacity: 0;
    pointer-events: none;
    transition: $transition;
    transition-delay: 200ms;
    transform: translateY(15px);

    gap: 16px;
    @media #{$pc} {
      padding: 32px;
      border-radius: 0px 40px 0px 0px;
    }
    @media #{$sp} {
      padding: 16px;
      border-radius: 0px 24px 0px 0px;
    }
    @media #{$pc} {
      margin: -40px 58px 0;
    }
    @media #{$sp} {
      margin: -40px 16px 0;
    }
  }
  .swiper-slide-active {
    .p-content {
      opacity: 1;
      pointer-events: all;
      transform: translateY(0);
    }
  }
  .p-title {
    display: -webkit-box;
    overflow: hidden;
    color: var(--Black, #232323);
    /* Noto 22-32 Bold */
    font-size: 22px;
    font-weight: 700;
    line-height: 32px; /* 145.455% */
    letter-spacing: 0.88px;

    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
  a:hover {
    .p-image {
      img {
        transform: scale(1.05);
      }
    }
  }
}

.top-journal__btn {
  padding: 40px 0 0;
  .c-btn-link__more {
    margin: 0 auto;
  }
}
.top-works__btn {
  padding: 40px 0;
  @media #{$sp} {
    padding-bottom: 80px;
  }
  .c-btn-link__more {
    margin: 0 auto;
  }
}
.top-topics__btn {
  @media #{$sp} {
    // margin-bottom: -40px;
    padding: 40px 0 0;
    .c-btn-link__more {
      margin: 0 auto;
    }
  }
}
