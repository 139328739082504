@charset 'UTF-8';
@use "sass:math";
@use "../foundation/variables" as *;
@use "../foundation/mixin" as *;
@use "../foundation/base64" as *;
.c-loading {
  overscroll-behavior-y: contain;
  display: flex;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0;
  align-items: center;
  justify-content: center;
  transition: $transition;
  pointer-events: none;

  &.is-active {
    opacity: 1;
    pointer-events: all;
  }
  &.--hide {
    opacity: 0;
    pointer-events: none;
  }
  .p-loading__logo {
    // width: 500px;
    // height: 500px;
    width: 100%;
    svg {
      width: 100%;
      height: auto;
    }
  }
}
