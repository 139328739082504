@charset 'UTF-8';
@use "sass:math";
@use "../foundation/variables" as *;
@use "../foundation/mixin" as *;
@use "../foundation/base64" as *;

.c-hero {
  position: relative;
  .p-bg_image {
    overflow: hidden;
    position: relative;
    z-index: 1;
    @media #{$pc} {
      // aspect-ratio: 1336 / 660;
      aspect-ratio: 1336 / 590;
    }
    @media #{$sp} {
      height: 80svh;
    }
    img {
      width: 100%;
      height: 100%;
      transform: scale(1.1);

      filter: blur(20px);
      object-fit: cover;
    }
  }
}

.c-pickup__main {
  display: flex;
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  @media #{$pc} {
    width: 100%;
  }
  @media #{$sp} {
    flex-direction: column;
    width: calc(100% - 0px);
  }
  .p-image__container {
    position: relative;
    @media #{$pc} {
      width: percentage(math.div(840, 1336));
    }
  }
  .p-image {
    overflow: hidden;
    position: relative;
    @media #{$pc} {
      border-radius: 0 80px 0 0;
      transform: translateY(60px);
    }
    @media #{$sp} {
      position: relative;
      width: calc(100% - 24px);
      border-radius: 0 40px 0 0;
    }
  }
  .p-content {
    //
    display: flex;
    flex-direction: column;
    margin-top: auto;

    @media #{$pc} {
      max-width: 360px + 120;
      width: 100%;
      padding: 40px 60px;

      gap: 24px;
    }
    @media #{$sp} {
      padding: 20px 24px 40px;

      gap: 18px;
    }
  }

  .p-title {
    display: -webkit-box;
    overflow: hidden;
    color: #ffffff;
    /* Noto 28 Bold */
    font-weight: 700;
    font-style: normal;
    letter-spacing: 2.24px;

    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    a {
      color: inherit;
      transition: $transition;

      &:hover {
        opacity: 0.5;
      }
    }
    @media #{$pc} {
      font-size: 28px;
      line-height: 1.5;
    }
    @media #{$sp} {
      font-size: 22px;
      line-height: 1.3;
    }
  }
  .p-date {
    color: $color-primary;
    /* Poppins 14 Medium */
    font-family: $ffEng;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 100%; /* 14px */
    @media #{$sp} {
      display: none;
    }
  }
}
.c-hero__sub {
  display: flex;
  // overflow: hidden;
}
.c-scroll {
  display: flex;
  flex-direction: column;

  align-items: center;
  gap: 8px;
  @media #{$pc} {
    margin: 35px 57px 0 24px;
    padding-top: 60px;
  }
  @media #{$sp} {
    position: absolute;
    z-index: 10;
    right: 5px;
    bottom: 0px;
  }
  .p-title {
    display: block;
    /* Poppins 10 Medium */
    font-family: $ffEng;
    font-size: 10px;
    font-weight: 500;
    font-style: normal;
    line-height: 100%; /* 10px */

    writing-mode: vertical-rl;
    @media #{$pc} {
      color: $color-black;
    }
    @media #{$sp} {
      color: #ffffff;
    }
  }
  .p-scroll-bar {
    overflow: hidden;
    position: relative;
    width: 1px;
    height: 96px;
    @media #{$pc} {
      background-color: #ebe6e1;
    }
    @media #{$sp} {
      background-color: $color-primary;
    }
    span {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 24px;
      animation: scrollAnimation 1.5s ease infinite;
      @media #{$pc} {
        background-color: #232323;
      }
      @media #{$sp} {
        background-color: #ffffff;
      }
    }
  }
}

@keyframes scrollAnimation {
  0% {
    transform: translateY(-24px);
  }

  50%,
  100% {
    transform: translateY(96px);
  }
}
.c-pickup {
  display: flex;
  overflow: hidden;
  position: relative;
  background-color: $color-primary;

  flex: 1;
  @media #{$pc} {
    padding: 100px 0 38px 40px;
    border-radius: 0px 0px 0px 80px;

    gap: 40px;
  }
  @media #{$sp} {
    // overflow: auto;
    flex-direction: column;
    z-index: 2;
    width: calc(100% - 24px);
    margin-left: 24px;
    padding: 24px 0 (24px + 32 + 32) 24px;
    border-radius: 0px 0px 0px 40px;
    transform: translateY(-24px);

    gap: 24px;
  }
  .c-pickup__list_ui {
    display: flex;
    position: absolute;

    gap: 16px;
    @media #{$pc} {
      top: 24px;
      right: 88px;
    }
    @media #{$sp} {
      bottom: 24px;
      left: 50%;
      transform: translateX(-50%);
    }
    .p-prev {
      transform: rotate(180deg);
    }
    .p-prev,
    .p-next {
      cursor: pointer;
    }
  }
  .p-pickup__title {
    color: $color-black;
    /* Monserrat 24 Bold */
    font-family: $ffEngSub;
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    line-height: 100%; /* 24px */
    white-space: nowrap;
  }
  .p-bar {
    width: 1px;
    height: 100%;
    background-color: #ffffff;
    @media #{$sp} {
      display: none;
    }
  }
  .c-pickup__list_container {
    overflow: hidden;

    @media #{$pc} {
      padding-right: 40px;

      flex: 1;
    }
    @media #{$sp} {
      width: 100%;
      // padding-right: 24px;
    }
  }
  .c-pickup__list {
    // display: flex;
    // flex: 1;
    // @media #{$pc} {
    //   gap: 40px;
    // }
    @media #{$sp} {
      // overflow: visible !important;
      // overflow-x: auto;

      // gap: 24px;
    }
    .p-item {
      @media #{$pc} {
        max-width: 322px;
        width: 322px;
      }
      @media #{$sp} {
        // max-width: 272px;
        // width: 272px;
      }
      a {
        display: flex;

        align-items: flex-start;
        gap: 16px;
        &:hover {
          .p-image {
            img {
              transform: scale(1.05);
            }
          }
        }
      }
      .p-content {
        display: flex;
        flex-direction: column;

        @media #{$pc} {
          flex: 1;
          gap: 16px;
        }
        @media #{$sp} {
          width: 156px;

          gap: 4px;
        }
      }
      .p-image {
        overflow: hidden;
        @media #{$pc} {
          width: 150px;
        }
        @media #{$sp} {
          min-width: 100px;
          width: 100px;
        }
        img {
          transition: $transition;

          object-fit: cover;
          @media #{$pc} {
            aspect-ratio: 150 / 100;
          }
          @media #{$sp} {
            aspect-ratio: 1/1;
          }
        }
      }
      .p-category {
        color: #ffffff;
        /* Noto 12-20 Bold */
        font-size: 12px;
        font-weight: 700;
        font-style: normal;
        line-height: 20px; /* 166.667% */
        letter-spacing: 0.96px;
      }
      .p-title {
        display: -webkit-box;
        overflow: hidden;
        color: $color-black;
        /* Noto 14-21 Bold */
        font-size: 14px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: 0.08em;

        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        @media #{$pc} {
          line-height: 21px; /* 150% */
        }
        @media #{$sp} {
          line-height: 1.4; /* 150% */
        }
      }
    }
  }
}
