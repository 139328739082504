@charset 'UTF-8';
@use "sass:math";
@use "../foundation/variables" as *;
@use "../foundation/mixin" as *;
@use "../foundation/base64" as *;
.c-category__list {
  display: flex;

  flex-wrap: wrap;
  gap: 16px;
  .p-category__item {
    display: block;
    width: fit-content;
    border-radius: 999px;
    background: $color-primary;
    color: #ffffff;
    /* Noto 14-21 Bold */
    font-weight: 700;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.08em;
    transition: $transition;
    @media #{$pc} {
      padding: 9px 16px;
      font-size: 14px;
    }
    @media #{$sp} {
      padding: 6px 10px;
      font-size: 11px;
    }
    &:hover {
      background-color: $base-color;
    }
  }
}

.c-tag__list {
  display: flex;

  flex-wrap: wrap;
  justify-content: flex-start;
  @media #{$pc} {
    gap: 16px;
  }
  @media #{$sp} {
    gap: 0.25em 0.5em;
  }
  li {
    color: $color-primary;
    /* Noto 14-21 Bold */
    font-size: 14px;
    // font-weight: 700;
    line-height: 1.5; /* 150% */
    letter-spacing: 0.08em;
    transition: $transition;

    &:hover {
      color: $base-color;
    }
  }
  a {
    color: $color-primary;
    transition: $transition;

    &:hover {
      color: $base-color;
    }
  }
}
