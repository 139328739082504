button {
  border: none;
  background-color: transparent;
  background-color: transparent;
  outline: none;

  appearance: none;
}
ul,
ol {
  list-style: none;
  li {
    list-style: none;
  }
}
