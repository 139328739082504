@charset 'UTF-8';
@use "sass:math";
@use "foundation/variables" as *;
@use "foundation/mixin" as *;
@use "foundation/base64" as *;
/* Default  Setting ===================================================================================== */
* {
  box-sizing: border-box;

  -webkit-backface-visibility: hidden;
  -webkit-print-color-adjust: exact;
}

html {
  height: 100%;
  background-color: #ffffff;
  font-size: 10px;

  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
}

body {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  color: $textColor;
  font-family: $fontFamily;
  font-weight: $regular;
  line-height: math.div(24, 14);

  @include font-size(1.4);
  line-break: strict;
  -webkit-overflow-scrolling: touch;
  &:not(.home) {
    @media #{$pc} {
      padding-top: 120px;
    }
    @media #{$sp} {
      padding-top: 80px;
    }
  }
}
body.is-lock {
  overflow: hidden !important;
  .c-header_logo {
    opacity: 0;
  }
}
// スクロールバー全体
::-webkit-scrollbar {
  width: 0.8rem;
  height: 0.8rem;
}

// スクロールバーの軌道
::-webkit-scrollbar-track {
  border-radius: 999rem;
  background-color: #f7f7f9;

  // box-shadow: inset 0 0 0.6rem darken(#e1e5ec, 20);
}

// スクロールバーの動く部分
::-webkit-scrollbar-thumb {
  border-radius: 999rem;
  background-color: #e1e5ec;
  // background-color: transparent;
  // box-shadow: inset 0 0 0.6rem darken(#e1e5ec, 30);

  // box-shadow: 0 0 0 0.1rem rgba(255, 255, 255, 0.3);
}

img {
  max-width: 100%;
  width: 100%;
  height: auto;
  border: none;
  -ms-interpolation-mode: bicubic;
  vertical-align: bottom;
}

::-moz-selection {
  background-color: $color-primary;
  color: #ffffff;
}

::selection {
  background-color: $color-primary;
  color: #ffffff;
}

iframe {
  border: none;
}

i {
  font-style: normal;
}

a {
  color: $textColor;
  text-decoration: none;
  transition: $transition;
}
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
  &:hover {
    @media #{$pc} {
      // opacity: .5;
    }
  }
}

a,
a img {
  outline: none;
  text-decoration: none;
  cursor: pointer;
  transition: $transition;
  transition-property: opacity, color;
}
.u-underline {
  text-decoration: underline;
}

.bold,
.strong,
b,
strong {
  font-weight: $bold;
}

em {
  color: $keycolor;
}
sup {
  position: relative;
  top: -0.15em;
  font-size: 0.75em;
}
.small {
  font-size: 0.8em;
}

.underline {
  text-decoration: underline;
}

.wbr {
  display: inline-block;
}

@media #{$pc} {
  .sp {
    display: none !important;
  }
}
@media #{$sp} {
  .pc {
    display: none !important;
  }
}

.hiddenSP {
  @media #{$sp} {
    display: none;
  }
}
.hiddenPC {
  @media #{$pc} {
    display: none;
  }
}
.is-hidden {
  display: none;
}

.hide {
  display: none;
}

.hide-sp {
  @media #{$sp} {
    display: none;
  }
}

.u-aling__center {
  text-align: center !important;
}
.u-aling__right {
  text-align: right !important;
}
.u-aling__left {
  text-align: left !important;
}
