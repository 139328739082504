@charset 'UTF-8';
@use "sass:math";
@use "../foundation/variables" as *;
@use "../foundation/mixin" as *;
@use "../foundation/base64" as *;
/* ====================================================================
footer
==================================================================== */
.l-footer {
  background-size: cover;
  @media #{$pc} {
    background-image: url(../images/bg_footer.jpg);
  }
  @media #{$sp} {
    background-image: url(../images/bg_footer_sp.jpg);
  }
  a {
    transition: $transition;
    &:hover {
      @media #{$pc} {
        opacity: 0.5;
      }
    }
  }
}
.l-footer__container {
  display: flex;
  flex-direction: column;
  max-width: 1100px + 48;
  width: 100%;
  margin: auto;
  padding: 64px 24px;
  @media #{$pc} {
    gap: 300px;
  }
  @media #{$sp} {
    justify-content: space-between;
  }
  .p-container {
    display: flex;
    @media #{$pc} {
      align-items: flex-end;
      justify-content: space-between;
    }
    @media #{$sp} {
      flex-direction: column;
      padding: 0 0;
    }
    .p-content {
      display: flex;

      @media #{$pc} {
        align-items: center;
        gap: 24px;
      }
      @media #{$sp} {
        flex-direction: column;
        padding-bottom: 60px;

        gap: 40px;
      }
    }
  }
}
.c-footer_logo {
  width: 180px;
  @media #{$sp} {
    order: 2;
  }
}

.c-footer-navi {
  @media #{$sp} {
    padding: 0 0 120px;
  }
  ul {
    display: flex;
    color: #ffffff;
    /* Monserrat 15 Bold */
    font-family: $ffEngSub;
    font-size: 15px;
    font-weight: 700;
    font-style: normal;
    line-height: 100%; /* 15px */

    flex-wrap: wrap;
    @media #{$pc} {
      gap: 24px;
    }
    @media #{$sp} {
      flex-direction: column;

      gap: 40px;
    }
  }
  a {
    color: #ffffff;
  }
}

.c-footer-navi__sub {
  display: flex;
  @media #{$pc} {
    gap: 24px;
  }
  @media #{$sp} {
    flex-direction: column;

    gap: 40px;
  }
  a {
    color: #ffffff;
  }
}

.c-copyright {
  color: #ffffff;
  font-weight: 500;
  font-style: normal;
  line-height: 28px; /* 233.333% */
  letter-spacing: 0.72px;
  @media #{$pc} {
    font-size: 12px;
  }
  @media #{$sp} {
    font-size: 10px;
  }
}
