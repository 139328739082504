@charset 'UTF-8';
@use "sass:math";
@use "../foundation/variables" as *;
@use "../foundation/mixin" as *;
@use "../foundation/base64" as *;
/* ====================================================================
header
==================================================================== */
.l-header {
  display: flex;
  // overflow: hidden;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;

  align-content: center;
  @media #{$pc} {
    height: 120px;
  }
  @media #{$sp} {
    height: 80px;
  }
  &:before {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    // background-color: #ebe6e1;
    background-color: rgba(#ebe6e1, 0.6);
    opacity: 0;
    pointer-events: none;
    content: "";
    transition: $transition;
    transform: translateY(-10%);

    backdrop-filter: blur(20px);
  }
}

.c-header_container {
  width: 100%;
  // width: 1100px;
  margin: auto;

  @media #{$pc} {
    // padding: 0 88px;
    padding: 0 60px;
  }
  @media #{$sp} {
    padding: 0 16px;
  }
  .c-header_container {
    //
  }
  .p-logo {
    height: auto;
    transition: $transition;
  }
  .p-logo__sub {
    height: auto;
    transition: $transition;
  }
}
.c-header_logo {
  display: flex;
  transition: $transition;

  align-items: flex-start;
  filter: invert(1);
  @media #{$pc} {
    gap: 24px;
  }
  @media #{$sp} {
    flex-direction: column;

    gap: 7px;
  }

  .p-logo {
    height: auto;
    @media #{$pc} {
      width: 200px;
    }
    @media #{$sp} {
      width: 113px;
    }
  }
  .p-logo__sub {
    height: auto;
    @media #{$pc} {
      width: 257px;
    }
    @media #{$sp} {
      width: 216px;
    }
  }
  .p-logo__sub02 {
    @media #{$sp} {
      width: 118px;
    }
  }
}
.is-fixed--contents {
  .l-header {
    &:before {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
body:not(.home) {
  .l-header {
    .c-header_logo {
      align-items: center;
      filter: invert(1);
      @media #{$sp} {
        flex-direction: row;

        align-items: flex-start;
      }
      // .p-logo__sub02 {
      //   @media #{$pc} {
      //     display: none;
      //   }
      //   @media #{$sp} {
      //     display: block;
      //   }
      // }
      .p-logo__sub {
        @media #{$pc} {
          // display: none;
        }
        @media #{$sp} {
          display: none;
        }
      }
      .p-logo__sub02 {
        @media #{$pc} {
          display: none;
        }
        @media #{$sp} {
          display: block;
          opacity: 1;
        }
      }
    }
  }
}
body.home {
  .c-header_logo {
    filter: invert(0);
    @media #{$pc} {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    @media #{$sp} {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .p-logo__sub02 {
      @media #{$pc} {
        display: none;
      }
      @media #{$sp} {
        display: none;
      }
    }
  }
  &.is-fixed--contents {
    .c-header_logo {
      filter: invert(1);
      @media #{$pc} {
        align-items: center;
      }
      @media #{$sp} {
        flex-direction: row;

        align-items: flex-start;
      }
      .p-logo__sub {
        @media #{$pc} {
          // display: none;
        }
        @media #{$sp} {
          display: none;
        }
      }
      .p-logo__sub02 {
        @media #{$pc} {
          display: none;
        }
        @media #{$sp} {
          display: block;
          opacity: 1;
        }
      }

      .p-logo {
        @media #{$pc} {
          width: 200px;
        }
        @media #{$sp} {
          width: 113px;
        }
      }
      .p-logo__sub {
        @media #{$pc} {
          width: 257px;
        }
        @media #{$sp} {
          width: 216px;
        }
      }
    }
  }
  .c-header_container {
    .p-logo {
      @media #{$pc} {
        width: 320px;
      }
      @media #{$sp} {
        width: 216px;
      }
    }
    .p-logo__sub {
      @media #{$pc} {
        width: 257px;
      }
      @media #{$sp} {
        width: 216px;
      }
    }
  }
}
.c-navi__toggle {
  position: fixed;
  z-index: 1000 + 2;
  top: 0;
  right: 0;
  background-color: #232323;
  color: #ffffff;
  cursor: pointer;
  @media #{$pc} {
    width: 120px;
    height: 120px;
    border-radius: 0 0 0 48px;
  }
  @media #{$sp} {
    width: 80px;
    height: 80px;
    border-radius: 0 0 0 24px;
  }
  .p-container {
    display: flex;
    flex-direction: column;

    gap: 8px;
  }
  .p-line {
    position: relative;
    // display: flex;
    // flex-direction: column;
    // height: 32px;
    margin: auto;
    // gap: 8px;
    @media #{$pc} {
      width: 32px;
      height: 19px;
    }
    @media #{$sp} {
      width: 24px;
      height: 19px;
    }
    span {
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #ffffff;
      transition: $transition;
      // transform-origin: center;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: calc(50% - 1px);
      }
      &:nth-child(3) {
        bottom: 0;
      }
    }
    .p-text {
      color: #ffffff;
      font-family: $ffEng;
      font-size: 11px;
      font-weight: 600;
      font-style: normal;
      line-height: 100%; /* 11px */
    }
  }
  .p-text {
    color: #ffffff;
    font-family: $ffEng;
    font-weight: 600;
    font-style: normal;
    text-align: center;
    line-height: 100%; /* 11px */
    @media #{$pc} {
      font-size: 11px;
    }
    @media #{$sp} {
      font-size: 10px;
    }
  }
  &.is-active {
    .p-line {
      span {
        &:nth-child(1) {
          top: calc(50% - 1px);
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          bottom: calc(50% - 1px);
          transform: rotate(-45deg);
        }
      }
    }
  }
}
