@charset 'UTF-8';
@use "sass:math";
@use "../foundation/variables" as *;
@use "../foundation/mixin" as *;
@use "../foundation/base64" as *;
.c-title__primary {
  display: flex;
  // flex-direction: column;
  align-items: flex-start;
  margin: auto;
  width: 100%;
  max-width: 1160px;
  @media #{$pc} {
    padding: 0 0 40px;

    gap: 40px;
  }
  @media #{$sp} {
    padding: 0 0 40px;

    // padding: 0 24px 40px;
    gap: 24px;

    flex-direction: column;
  }

  &.--vertical {
    @media #{$pc} {
      padding: 0 0 80px;
    }
    align-items: center;
    width: fit-content;
    margin: auto;
    > .p-title__container {
      @media #{$pc} {
        align-items: center;
      }
      @media #{$sp} {
        align-items: center;
      }
    }
    .p-title__line {
      width: 48px;
      height: 1px;
      // margin-top: 8px;
      margin: 8px auto 0;

      background: var(--Beige, #ebe6e1);
    }
    &.--sp__left > .p-title__container {
      @media #{$sp} {
        align-items: flex-start !important;
        padding: 0 24px;
        .p-title__line {
          margin: 0 0 0 0 !important;
        }
      }
    }
    .p-lead {
      padding-top: 0px;
    }
  }
  > .p-title__line {
    background: var(--Beige, #fff);
    @media #{$pc} {
      width: 1px;
      height: 48px;
    }
    @media #{$sp} {
      width: 48px;
      height: 1px;
    }
  }
  > .p-title__container {
    display: flex;
    gap: 16px;
    flex-direction: column;
    @media #{$pc} {
      // align-items: center;
      align-items: flex-start;
    }
    @media #{$sp} {
      align-items: flex-start;
    }
    // flex-direction: column;
    > .p-title {
      color: $color-black;
      /* Monserrat 32 Bold */
      font-family: $ffEngSub;
      font-size: 32px;
      font-weight: 700;
      font-style: normal;
      line-height: 0.8;
      // line-height: 100%; /* 32px */
    }
    > .p-title__sub {
      color: $color-primary;
      /* Noto 14-21 Bold */

      font-size: 14px;
      font-weight: 700;
      font-style: normal;
      line-height: 1;
      // line-height: 1.5; /* 150% */
      letter-spacing: 0.08em;
    }

    > .p-lead {
      /* Noto 14-26 Medium */
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.85; /* 185.714% */
      letter-spacing: 0.08em;
    }
  }
  > .c-btn-link__more {
    margin-left: auto;
    @media #{$sp} {
      display: none;
    }
  }
  .p-lead {
    @media #{$pc} {
      padding-top: 2px;
    }
  }
  .bg-gold & {
    .p-title__sub {
      color: #fff;
    }
  }
}
