@charset 'UTF-8';
@use "sass:math";
@use "../foundation/variables" as *;
@use "../foundation/mixin" as *;
@use "../foundation/base64" as *;
.top-contact {
  .c-title__primary > .p-title__container > .p-title {
    color: #ffffff;
  }
  .p-button {
    display: block;
    position: relative;
    z-index: 1;
    padding: 100px 30px;
    padding-bottom: 0 !important;
    background-color: $color-primary;
    @media #{$sp} {
      padding-bottom: 100px !important;
    }
    &:after {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #232323;
      content: "";
      transition: $transition;
    }
    .p-title__sub {
      transition: $transition;
    }
    &:hover {
      &:after {
        transform: translateX(100%);
      }
      .p-title__sub {
        color: $color-black;
      }
    }
  }
  header {
    position: relative;
    z-index: 3;
    color: #ffffff;
    .p-title {
      color: #ffffff;
    }
  }
}
