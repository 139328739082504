@charset 'UTF-8';
@use "sass:math";
@use "../foundation/variables" as *;
@use "../foundation/mixin" as *;
@use "../foundation/base64" as *;

.contact-main{
  .c-title__section{
    padding-left: 0;
    
    @media #{$tb} {
      padding-bottom: 40px;
    }
  }
}
.contact{
  &-head{
    margin-top: -80px;
    
    @media #{$tb} {
      margin-top: -10px;
    }
  &__lead{
    font-size: 14px;
    font-weight: 500;
    line-height: 1.85;
  }
  &__policy{
    border: 1px solid $base-color;
    height: 200px;
    overflow: auto;
    padding: 40px;
    margin-top: 40px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.85;
    
    @media #{$sp} {
      padding: 24px;
      margin-top: 24px;
      height: 160px;
    }    
    em{
      font-weight: 700;
      line-height: 1.5;
    }
  }
  }
  &-body{
    margin-top: 80px;
    
    @media #{$sp} {
      margin-top: 60px;
    }    
    &__ttl{
      font-size: 22px;
      font-weight: 700;
      line-height: 1.45;
      text-align: center;
      margin-bottom: 40px;
    
      @media #{$sp} {
        font-size: 22px;
        margin-bottom: 40px;
      }
    }
    &__policy{
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.85;
    
      @media #{$sp} {
        margin-top: 40px;
      }      
      input + span{
        margin-left: 12px;
        position: relative;
        top: -2px;
      }   
      &>label{
        &>span{
          margin-right: -1em;
          position: relative;
          top: 2px; 
        }
      }
      a{
        position: relative;
        z-index: 2;
        text-decoration: underline;
        
        &:hover{
          text-decoration: none;
        }
      }
    }
    &__submit{
      margin-top: 40px;
      display: flex;
      justify-content: center;
    
      &>*{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
      }
      @media #{$sp} {
        margin-top: 40px;
      }      
      input,  
      button{
        width: 100%;
        max-width: 320px;
        height: 48px;
        background-color: $base-color;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFF;
        border: none;
        font-size: 16px;
        font-weight: 700;
        border-radius: 0 24px 0 0;
        cursor: pointer;
        transition: $transition;
        
        @media #{$sp} {
          max-width: 248px;
          height: 64px;
        }
        &:hover {
          @media #{$pc} {
            background-color: $color-primary;
          }
        }
      }
    }
    &__recaptcha{
      font-size: 14px;
      font-weight: 500;
      line-height: 1.85;
      margin-top: 40px;
      text-align: center;
      
      a{
        text-decoration: underline;
        
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
}
.grecaptcha-badge {
  visibility: hidden;
}
.block-input{
  &+.block-input{
    margin-top: 24px;
    
    @media #{$sp} {
      margin-top: 24px;
    }  
  }
  input,
  textarea{
    font-size: 14px;
    padding: 20px 24px;
    border: 1px solid $base-color;
    width: 100%;
    
    @media #{$sp} {
      padding: 20px 24px;
      border-radius: 4px;
    }  
  }
  textarea{
    height: 180px;
  }
  &__select{
    position: relative;
    
    &:before{
      content: "";
      width: 6px;
      height: 12px;
      background: url("../images/icon_arrow.svg") no-repeat center center;
      background-size: contain;
      position: absolute;
      right: 22px;
      top: 50%;
      transform: translateY(-50%) rotate(90deg);
      pointer-events: none;
      z-index: 2;
    }
    select{
      appearance: none; /* 標準のスタイルを無効にする */
      width: 100%;
      padding: 20px 24px;
      background-color: $base-color;
      color: #FFF;
      border: none;
      font-size: 14px;
      border-radius: 4px;
    }
  }
}

.complete-content{
  text-align: center;
  padding-top: 110px;
  padding-bottom: 80px;
    
  @media #{$sp} {
    padding-top: 60px;
    padding-bottom: 60px;
  }  
  &__ttl{
    font-size: 22px;
    font-weight: 700;
    line-height: 1.45;
  }
  &__body{
    font-size: 14px;
    font-weight: 500;
    line-height: 1.85;
    margin-top: 40px;
    
    @media #{$sp} {
      text-align: left;
    }
  }
  &__btn{
    margin-top: 80px;
    
    .c-btn-link__more {
      margin: 0 auto;
      
      a{
        flex-direction: row-reverse;
        
        i{
          transform: rotate(180deg);
        }
        &:hover {
          span {
            transform: translateX(-5px);
          }
        }
      }
    }
  }
}




    @media #{$pc} {
    }
    @media #{$sp} {
    }