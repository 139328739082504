@charset 'UTF-8';
@use "sass:math";
@use "../foundation/variables" as *;
@use "../foundation/mixin" as *;
@use "../foundation/base64" as *;
/* ====================================================================
navi
==================================================================== */
.c-global-navi {
  // min-height: 100%;
  overflow-y: auto;
  position: fixed;
  z-index: 1000 + 1;
  top: 0;
  left: -150%;
  width: 100%;
  max-height: 100vh;
  max-height: 100dvh;
  height: 100%;
  background-color: #ffffff;
  opacity: 0;
  pointer-events: all;
  transition:
    opacity,
    transform $transition;
  transform: scale(1.05);

  overscroll-behavior-y: contain;

  @media #{$pc} {
    padding: 160px 0 80px;
  }
  @media #{$sp} {
    padding: 100px 24px 90px;
  }
  .home & {
    @media #{$pc} {
      padding-top: 160px;
    }
    @media #{$sp} {
      padding-top: 100px;
    }
  }
  &.is-active {
    left: 0;
    opacity: 1;
    pointer-events: all;
    transform: scale(1);
  }
  .p-global-navi__list {
    // overflow-y: auto;
  }
  .p-navi__sub {
    display: flex;
    position: absolute;
    top: 10px;
    right: 120px + 40;
    padding: 20px 0;

    gap: 40px;
    .p-hedding {
      gap: 12px;
    }
    .p-item {
      padding-top: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
    .p-line {
      background-color: $color-black;
    }

    a {
      transition: $transition;
      &:hover {
        @media #{$pc} {
          opacity: 0.5;
        }
      }
    }
  }
  .p-item {
    @media #{$pc} {
      padding: 40px 0;
      border-bottom: 1px solid #232323;
    }
    @media #{$sp} {
      padding: 20px 0;
    }
  }
  .p-container {
    display: flex;
    max-width: 1100px + 48;
    margin: auto;
    @media #{$pc} {
      padding: 0 24px;

      gap: 40px;
    }
    @media #{$sp} {
      gap: 15px;
      justify-content: space-between;
    }
  }
  .p-line {
    width: 1px;
    // height: 100%;
    background-color: #ebe6e1;
    @media #{$sp} {
      display: none;
    }
  }
  .p-hedding {
    display: flex;
    flex-direction: column;
    @media #{$pc} {
      gap: 16px;
    }
    @media #{$sp} {
      gap: 12px;
    }
    .p-text {
      padding-top: 8px;
    }
  }
  .p-title {
    color: $color-black;
    /* Monserrat 32 Bold */
    font-family: $ffEngSub;
    font-weight: 700;
    font-style: normal;
    line-height: 100%; /* 32px */
    @media #{$pc} {
      font-size: 32px;
    }
    @media #{$sp} {
      font-size: 24px;
    }
  }
  .p-title__sub {
    color: $color-primary;
    /* Noto 14-21 Bold */
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    line-height: 1.5; /* 150% */
    letter-spacing: 0.08em;
  }
  .p-text {
    color: $color-black;
    /* Noto 14-26 Medium */
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.85; /* 185.714% */
    letter-spacing: 0.06em;

    flex: 1;
    @media #{$pc} {
      padding-top: 3px;
    }
    @media #{$sp} {
      display: none;
    }
  }

  .c-category__list,
  .c-tag__list {
    padding-top: 24px;
    @media #{$sp} {
      display: none;
    }
  }
}

.c-global-navi__service {
  display: flex;

  gap: 4px;
  @media #{$sp} {
    display: none;
  }
  .p-service__title {
    color: $color-black;
    /* Noto 12-20 Bold */
    font-size: 11px;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    line-height: 20px; /* 166.667% */
    // letter-spacing: 0.96px;
  }
  .p-service__image {
    min-width: 210px;
    // min-width: (168px - 10) + 32 * 2;
    padding: 0 24px;
  }
}
