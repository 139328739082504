@charset 'UTF-8';
@use "sass:math";
@use "../foundation/variables" as *;
@use "../foundation/mixin" as *;
@use "../foundation/base64" as *;
.c-btn__more {
  &.--gold {
    a {
      background-color: $color-primary;
      &:hover {
        background-color: #ebe6e1;
        color: $color-black;
        span {
          color: $color-black;
        }
        .p-btn__more_icon {
          filter: invert(1);
          // @include icon_arrow(232323);
        }
      }
    }
  }
  a {
    display: flex;
    width: fit-content;
    width: 216px;
    height: 64px;
    padding: 0 27px;
    border-radius: 32px 32px 32px 0px;
    background: $color-black;
    color: #ffffff;
    /* Poppins 14 SemiBold */
    font-family: $ffEng;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    line-height: 0.8;
    transition: $transition;

    align-items: center;
    justify-content: space-between;
    @media #{$sp} {
      margin: auto;
    }
    &:hover {
      @media #{$pc} {
        background-color: $color-primary;
        .p-btn__more_icon {
          transform: translateX(3px);
        }
      }
    }
  }
  span {
    transition: $transition;

    color: #ffffff;
  }
  .p-btn__more_icon {
    @include icon_arrow(ccc);
    width: 6px;
    height: 12px;
    transition: $transition;
  }
}

.c-btn-link__more {
  width: fit-content;
  margin-left: auto;
  a {
    display: flex;
    margin-left: auto;
    white-space: nowrap;

    align-items: center;
    gap: 16px;
  }
  span {
    color: $color-black;
    font-family: $ffEng;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    line-height: 100%; /* 14px */
    transition: $transition;
  }
  &.sp-text__hide {
    span {
      @media #{$sp} {
        display: none;
      }
    }
  }
  i {
    display: block;
    width: 48px;
    height: 48px;
    transition: $transition;
    @include icon_arrow_circle02(232323);
  }
  img {
    width: 48px;
    height: auto;
    display: none;
    transition: $transition;
  }
  &:hover {
    span {
      transform: translateX(5px);
    }
  }
}
