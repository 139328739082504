@charset 'UTF-8';
@use "sass:math";
@use "../foundation/variables" as *;
@use "../foundation/mixin" as *;
@use "../foundation/base64" as *;

.works-connection{
  margin-top: 80px;
  padding-top: 40px;
  position: relative;
  
  @media #{$sp} {
    margin-top: 60px;
  }  
  &>*{
    position: relative;
    z-index: 2;
  }
  &:before{
    content: "";
    background-color: #EBE6E1;
    width: 100%;
    height: 237px;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 1;
  }
  &__head{
    margin-bottom: 36px;
    display: flex;
    align-items: center;
  
    @media #{$sp} {
      flex-direction: column;
      align-items: flex-start;
    }  
  }
  &__label{
    font-size: 14px;
    font-weight: 700;
    background-color: $color-primary;
    line-height: 1.5;
    padding: .4em 1.5em;
    border-radius: 12px 0 0 0;
    margin-right: 16px;
  
    @media #{$sp} {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
  &__ttl{
    font-size: 22px;
    font-weight: 700;
    line-height: 1.45;
  }
  .c-card__list{  
    @media #{$sp} {
      padding: 0 24px;
      display: flex;
      flex-direction: column;
      gap: 40px;
    }
    .p-title{
      font-size: 14px;
      font-weight: 500;
      margin-top: -10px;
    }
    .p-item{
      @media #{$sp} {
        width: 100%;
      }
      
      .p-image{
        img{
          border-radius: 0 80px 0 0 !important;
  
          @media #{$sp} {
            border-radius: 0 40px 0 0 !important;
          }
        }
      }
    }
  }
}


    @media #{$pc} {
    }
    @media #{$sp} {
    }