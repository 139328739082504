@charset 'UTF-8';
@use 'sass:math';
@use '../foundation/variables' as *;
@use '../foundation/mixin' as *;
@use '../foundation/base64' as *;

// top 余白 ==================================================
.u-pdt0 {
  padding-top: 0rem !important;
}

.u-mgt0 {
  margin-top: 0rem !important;
}
@for $i from 1 through 20 {
  .u-pdt#{$i} {
    padding-top: 1rem * $i !important;
  }

  .u-mgt#{$i} {
    margin-top: 1rem * $i !important;
  }
}

// bottom 余白 ==================================================
.u-pdb0 {
  padding-bottom: 0rem !important;
}

.u-mgb0 {
  margin-bottom: 0rem !important;
}
@for $i from 1 through 20 {
  .u-pdb#{$i} {
    padding-bottom: 1rem * $i !important;
  }

  .u-mgb#{$i} {
    margin-bottom: 1rem * $i !important;
  }
}

// left 余白 ==================================================
.u-pdl0 {
  padding-left: 0rem !important;
}

.u-mgl0 {
  margin-left: 0rem !important;
}
@for $i from 1 through 20 {
  .u-pdl#{$i} {
    padding-left: 1rem * $i !important;
  }

  .u-mgl#{$i} {
    margin-left: 1rem * $i !important;
  }
}

// right 余白 ==================================================
.u-pdr0 {
  padding-right: 0rem !important;
}

.u-mgr0 {
  margin-right: 0rem !important;
}
@for $i from 1 through 20 {
  .u-pdr#{$i} {
    padding-right: 1rem * $i !important;
  }

  .u-mgr#{$i} {
    margin-right: 1rem * $i !important;
  }
}

// fontsize ==================================================
.u-fontsize8 {
  font-size: .8rem !important;
}
.u-fontsize9 {
  font-size: .9rem !important;
}

@for $i from 1 through 30 {
  .u-fontsize#{$i} {
    font-size: math.div((1rem * $i), 10) !important;
  }
}
