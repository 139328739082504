@charset 'UTF-8';
@use "sass:math";
@use "../foundation/variables" as *;
@use "../foundation/mixin" as *;
@use "../foundation/base64" as *;
.c-top-frendship__visual {
  overflow: hidden;
  @media #{$pc} {
    background-image: url(../images/frendship_bg.jpg);
    background-attachment: fixed;
    background-position: center;

    aspect-ratio: 1336 / 420;
  }
  @media #{$sp} {
    background-image: url(../images/frendship_bg_sp.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    aspect-ratio: 375 / 280;
  }
}
.c-top-frendship {
  display: flex;
  max-width: 880px;
  margin: auto;
  margin-top: -100px;
  border-radius: 0 80px 0 0;
  background-color: $color-black;
  color: #ffffff;
  @media #{$sp} {
    flex-direction: column;
    // margin-right: 24px;
    // margin-left: 24px;
  }
  .p-image__content {
    display: flex;

    align-items: center;
    justify-content: center;
    @media #{$pc} {
      width: 400px;
      height: 345px;
      padding: 0 10px;
    }
    @media #{$sp} {
      padding: 64px 10px 40px;
    }
    .p-image {
      @media #{$pc} {
        width: 200px;
        height: 180px;
      }
      @media #{$sp} {
        width: 160px;
        height: 144px;
      }
    }
  }
  .p-content {
    display: flex;
    flex-direction: column;
    padding: 56px 56px 56px 0;

    flex: 1;
    gap: 24px;
    @media #{$sp} {
      padding: 0px 24px 40px;
    }
  }
  .p-line {
    width: 48px;
    height: 1px;
    background-color: $color-primary;
  }
  .p-title {
    color: var(--White, #ffffff);
    /* Noto 24-42 Bold */
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    text-align: justify;
    line-height: 1.75; /* 175% */
    letter-spacing: 0.04em;
  }
  .p-text {
    color: var(--White, #ffffff);
    /* Noto 14-26 Medium */
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    text-align: justify;
    line-height: 1.85; /* 185.714% */
    letter-spacing: 0.08em;
  }
}

.c-marquee__bigtypo {
  @media #{$pc} {
    padding-top: 60px;
  }
  @media #{$sp} {
    padding-top: 24px;
  }
  .swiper-slide {
    @media #{$pc} {
      width: 845px + 80;
      padding-right: 80px;
    }
    @media #{$sp} {
      width: 240px + 32;
      padding-right: 32px;
    }
  }
  img {
    height: auto;
    @media #{$pc} {
      width: 845px;
    }
    @media #{$sp} {
      width: 240px;
    }
  }
}
