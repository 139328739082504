@charset 'UTF-8';
@use "sass:math";
@use "../foundation/variables" as *;
@use "../foundation/mixin" as *;
@use "../foundation/base64" as *;

.c-top-service__list {
  display: flex;
  margin: auto;

  gap: 10px;
  @media #{$sp} {
    flex-direction: column;
  }
  .p-item {
    display: block;
    background-color: #ffffff;
    cursor: pointer;
    @media #{$pc} {
      padding: 56px 40px;
      border-radius: 0 80px 0 80px;
    }
    @media #{$sp} {
      padding: 40px 24px;
      border-radius: 0 40px 0 40px;
    }
    &:hover {
      .c-btn-link__more span {
        transform: translateX(5px);
      }
    }
  }
  .p-title__small {
    color: $color-black;
    /* Noto 16-28 Bold */
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    line-height: 28px; /* 175% */
    letter-spacing: 0.64px;
  }
  .p-title {
    color: $color-black;
    /* Noto 24-42 Bold */
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    line-height: 42px; /* 175% */
    letter-spacing: 0.96px;
  }
  .p-text {
    color: $color-black;
    /* Noto 16-28 Medium */
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: 28px; /* 175% */
    letter-spacing: 0.96px;
  }
  .c-btn-link__more {
    padding-top: 40px;
    @media #{$pc} {
      display: flex;

      align-items: center;
      gap: 24px;
    }
  }
}
